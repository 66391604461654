import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Button.module.scss';
import { colorSchemes as colorSchemesConstant } from '../constants';

export const buttonSizes = {
    SUPER_MINI: 'superMini', // height: 20px, fontSize: 12px
    MINI: 'mini', // height: 22px, fontSize: 12px
    TIGHT: 'tight', // height: 28px, fontSize: 14px
    SMALL: 'small', // height: 32px, fontSize: 16px
};

export const buttonStyles = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    OUTLINE: 'outline',
};

export const buttonTypes = {
    BUTTON: 'button',
    SUBMIT: 'submit',
    RESET: 'reset',
};

export const colorSchemes = { ...colorSchemesConstant };

export default function Button({
    text,
    size = buttonSizes.SMALL,
    style = buttonStyles.PRIMARY,
    colorScheme = colorSchemes.LIGHT,
    disabled = false,
    type = buttonTypes.BUTTON,
    onClick = undefined,
}) {
    function handleClick(event) {
        if (onClick && !disabled) onClick(event);
    }

    return (
        <button
            type={type}
            disabled={disabled}
            className={classNames(styles.button, styles[size], styles[style], styles[colorScheme])}
            onClick={(event) => handleClick(event)}
        >
            {text}
        </button>
    );
}

Button.propTypes = {
    text: PropTypes.string.isRequired,
    size: PropTypes.oneOf(Object.values(buttonSizes)),
    style: PropTypes.oneOf(Object.values(buttonStyles)),
    colorScheme: PropTypes.oneOf(Object.values(colorSchemes)),
    disabled: PropTypes.bool,
    type: PropTypes.oneOf(Object.values(buttonTypes)),
    onClick: PropTypes.func,
};
